.columns {
  background-color: $white;
  border-radius: 12px;
  padding: 16px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  position: sticky;
  top: 16px;
  min-width: 240px;

  @include md() {
    width: 100vw;
    padding: 0;
    padding-top: 56px;
    margin-left: -24px;
    padding-left: 24px;
  }

  @include sm() {
    margin-top: 5px;
  }

  &__wrapper {
    @include md() {
      display: flex;
      overflow-x: scroll;
      margin-top: 24px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    @include md() {
      padding-bottom: 56px;
    }
  }

  @include md() {
    position: relative;
    margin-bottom: 24px;
    .btn {
      display: none;
    }
  }

  &__title-wrapper {
    display: flex;
    @include md() {
      padding-right: 24px;
    }
    justify-content: space-between;
    align-items: center;

    h2 {
      @include font(24, 33.5px, 600);
    }

    a {
      text-decoration: none;
      font-weight: 600;
      color: $main;
    }
  }

  &__item {
    border-radius: 0;
    padding: 24px 0;
    border-bottom: solid 1px $neutral-30;

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 71px;
      @include md() {
        padding-bottom: 0;
      }
    }

    @include md() {
      padding: 0;
      border-bottom: none;
      min-width: 328px;
      padding-right: 24px;
      padding-bottom: 10px;
    }
  }
}
