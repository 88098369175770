@mixin pseudo-overlap {
  content: '';
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  z-index: 5;
  top: 20px;
  left: -55px;
  width: 200px;
  height: 75px;
  // background: linear-gradient(90deg, rgba(24, 40, 64, 0.00) 3.52%, #182840 57%);
  background-image: url('/src/images/video/tint-right.png');

  @include lg-1400 {
    pointer-events: none;
    top: 15px;
  }

  @include sm {
    left: -61px;
    background: linear-gradient(90deg, rgba(24, 40, 64, 0.00) 3.52%, #182840 47%);
    width: 200px;
    top: 0;
  }
}


.heroes-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 30px;
  max-width: 1440px;

  @include sm {
    margin-left: 10px;
  }

  .splide__track {
    overflow: hidden;
    width: 100%;
  }

  button.splide__arrow[disabled] {
    display: none;
  }

  .splide__arrow {
    background-color: transparent;
    @include size(70px, 124px);
    border-radius: 0;
    opacity: 1;
    transform: none;
    top: -34px;

    &:hover {
      opacity: 1;
    }

    @include sm {
      @include size(25.5px, 56px);
      top: -11px;
    }

    svg {
      position: relative;
      z-index: 10;
      @include size(inherit, inherit);
      left: 75px;

      @include lg-1400 {
        left: 0;
      }
    }

    // &::before {
    //   @include pseudo-overlap();
    // }
  }

  .splide__arrow--prev {
    transform: rotate(180deg);
    left: 8px;

    &::after {
      @include pseudo-overlap();
    }

    @include sm {
      left: 5px;
      transform: translateX(-100%) rotate(180deg);
    }
  }

  .splide__arrow--next {
    right: 8px;

    &::after {
      @include pseudo-overlap();
    }

    @include sm {
      transform: translateX(100%);
      right: 5px;
    }
  }

  @include lg-1400 {
    justify-content: space-between;
    width: 100%;
    margin-left: 0;
  }

  .splide__slide:not(.is-visible) {
    &:last-child {
      opacity: 0;

      @include lg-1400 {
        opacity: 1;
      }
    }

    &:first-child {
      opacity: 0;

      @include lg-1400 {
        opacity: 1;
      }
    }
  }


  .splide__slide {
    position: relative;
    border-right: solid 1px $blue-main-border;
    padding-right: 27.5px;
    padding-left: 27.5px;
    width: fit-content;
    min-width: 190px;
    @include center-flex();

    @include md {
      min-width: auto;
    }

    &:first-of-type {
      padding-left: 0;

      @include lg-1400 {
        padding-left: 40px;
      }

      @include sm {
        padding-left: 18.5px;
      }
    }

    &:last-of-type {
      border: none;

      @include lg-1400 {
        border-right: solid 1px $blue-main-border;
      }
    }

    @include lg-1400 {
      height: 100%;
      width: 20vw;
      @include center-flex();
      padding: 0 40px;
    }

    @include sm {
      // max-width: 30.5vw;
      // width: fit-content;
      width: 28vw;
      padding: 0 18.5px;
      border-color: $neutral-80;
    }

    &.is-prev {
      @include sm {
        left: -2px;
        background-color: #182840;
        z-index: 1;
      }
    }

    &.is-next {
      @include sm {
        border-right: none;
      }
    }
  }

  &__tab {
    // padding-right: 32px;
    // padding-left: 32px;
    display: block;
    width: fit-content;

    height: fit-content;
    overflow-wrap: break-word;

    // @include lg {
    //   padding: 0 24px;
    // }

    // &:last-of-type {
    //   padding-right: 0;
    //   border-right: none;
    // }

    &_btn {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: #F6F6F6;
      @include font(24, 29px, 700);
      padding: 0;
      text-align: left;
      transition: color 0.2s ease;

      // @include md {
      //   @include font(16, 19.5px);
      // }

      @include sm {
        @include font(14, 17px);
      }

      &_active {
        color: $main;
      }
    }
  }
}