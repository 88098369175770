.videos-projects-head {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 24px;

  @include md {
    padding-bottom: 16px;
  }

  &__title {
    color: $main;
    @include font(32px, 41.6px, 700);

    @include sm {
      @include font(20px, 26px);
    }
  }

  &__btn {
    @include center-flex();
    color: $main;
    @include font(16px, 22.4px, 600);

    @include sm {
      @include font(14px, 20px);
    }

    >span {
      @include xs {
        display: none;
      }
    }

    svg {
      margin-left: 8px;

      @include xs {
        margin-left: 0;
      }
    }
  }
}