.view-item {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: $white;
  position: relative;
  transition: transform 0.2s ease-in-out;
  will-change: transform;

  &:hover {
    transform: translateY(2px) translateX(1.5px);
  }

  @include sm {
    min-width: 304px;
    max-width: 304px;
  }

  &__image {
    background-color: #182840;
    display: flex;
    min-height: 172px;
    height: 172px;
    justify-content: center;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
    z-index: 2;
    position: relative;

    >img {
      @include size(100%, 100%);
      filter: grayscale(1);
      object-fit: contain;
      transform: translateY(8%) scale(1.2);
    }
  }

  &__content {
    padding: 32px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;

    &::before {
      content: '';
      @include size(32px, 32px);
      position: absolute;
      transform: rotate(45deg);
      z-index: 1;
      left: 21px;
      top: -20px;
      background-color: $blue-main;
    }

    >div {
      height: 100%;
    }
  }

  &__title {
    @include font(18px, 21.6px, 500);
    color: $main;
    margin-bottom: 4px;
  }

  &__position {
    @include font(16px, 16px, 400);
    color: #707070;
  }

  &__head {
    margin-bottom: 16px;
  }

  &__description {
    @include font(16px, 20.8px, 700);
    margin-bottom: 24px;
    @include line-clamp(3);
    color: #000000;
  }

  &__date {
    @include font(12px, 16.8px, 500);
    color: #707070;
  }

}