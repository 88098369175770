.content-grid-three {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;

  @include md() {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  @include xs() {
    grid-template-columns: 1fr;
  }

  .grid-item {
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}