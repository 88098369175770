.broadcasts-sidebar {
  background-color: $white;
  width: 100%;
  max-width: 304px;
  padding: 16px;
  padding-bottom: 24px;
  border-radius: 12px;

  @include md {
    padding: 16px 24px 24px 24px;
    border-radius: 0;
    max-width: 100%;
  }

  @include md {
    margin-left: -24px;
    width: calc(100% + 24px);
  }

  &__title {
    @include font(24, 33.6px, 600);

    @include md {
      @include font(20, 28px, 600);
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__btn {
    background-color: $main;
    color: $white;
    border-radius: 12px;
  }

  &__date-group {
    margin: 16px 0;

    &_date {
      @include font(14, 14px, 700);
      color: $main-pressed;
      text-transform: uppercase;
      display: block;
      margin-bottom: 16px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__item {
    padding: 16px 0;
    border-bottom: solid 1px $neutral-30;
    display: flex;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }

    &_title {
      @include font(16, 22.4px, 500);
      color: $blue-main;
      @include line-clamp(3);
    }

    &_active {
      .broadcasts-sidebar__item_time {
        background-color: $main;
      }

      .broadcasts-sidebar__item_title {
        font-weight: 700;
      }
    }

    &_time {
      background-color: $blue-main;
      height: fit-content;
      margin-right: 8px;
      color: $white;
      padding: 6px 8px;
      border-radius: 6px;
      display: inline-block;
      @include font(14, 14px, 600);
      letter-spacing: 0.05em;
    }
  }

  &__btn {
    margin-top: 16px;
    background-color: $main;
    @include size(100%, 36px);
    color: $white;
    border-radius: 4px;
    @include center-flex();
    @include font(16, 16px, 500);
    padding-bottom: 2px;
  }
}