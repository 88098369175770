.sidebar-last,
.sidebar-last-mob {
  height: fit-content;

  &__items-list {
    display: none;

    &[data-tabData='all'] {
      display: block;
    }
  }
}

.sidebar-last,
.sidebar-last-mob,
.sidebar-popular {
  @include md() {
    max-width: 100%;
  }
}

.sidebar-popular {
  background-color: transparent;
  height: fit-content;

  .sidebar__inner {
    background-color: $white;
  }
}