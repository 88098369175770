.promo-slide {
  display: flex;
  // margin-top: -8px;

  width: 101% !important;

  @include sm() {
    flex-direction: column;
    justify-content: center;
  }

  >a {
    max-width: 304px;
    display: flex;
    width: 100%;
    height: 170px;

    @include sm {
      max-width: 100%;
      height: auto;
    }

    img {
      object-fit: cover;
      width: inherit;
      border-radius: 8px;
      height: auto;
    }
  }

  &__content {
    margin-left: 32px;
    width: 100%;

    @include sm() {
      margin-left: 0;
      margin-top: 12px;
    }
  }

  &__title {
    color: $neutral-100;
    margin-top: 8px;
    @include font(32px, 40px, 700);
    @include line-clamp(3);

    @include sm() {
      @include font(20px, 25px);
      max-width: 100%;
    }
  }

  &__date {
    @include font(12, 16px, 400);
    color: $neutral-80;
    margin-right: 16px;
  }

  &__text-important {
    color: $blue-main-border;
    @include font(14, 20px, 600);
    margin-left: 8px;
  }

  &__top-wrapper {
    display: flex;
    align-items: center;

    // >svg {
    //   position: relative;
    //   top: -3px;
    // }
  }
}