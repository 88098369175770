// Global params

$font-primary: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-weight-primary: 400 !default;

// Media
$desktop-width: 1280px;
$sm-desktop-width: 1024px;
$tablet-width: 768px;
$sm-tablet-width: 640px;
$phone-width: 480px;
$sm-phone-width: 375px;

// Main Colors
$text-color: #2D2D2D;
$white: #ffffff !default;
$body: white;
$main: #ff7534;
$main-border: #ffc1a3;
$main-surface: #ffeae0;
$main-hovered: #f66623;
$main-pressed: #f3560c;
$blue-main: #182840;
$blue-main-border: #979ea9;
$surface: #ffeae0;

$hover: #ebebeb;
$clicked: #dbdbdb;

// Neutral Colors
$neutral-10: #ffffff;
$neutral-20: #f7f7f7;
$neutral-30: #EBEBEB;
$neutral-40: #dedfe0;
$neutral-50: #c3c8cc;
$neutral-60: #b0b0b0;
$neutral-70: #D2D2D2;
$neutral-80: #ADADAD;
$neutral-90: #2D2D2D;
$neutral-100: #2d2d2d;