@mixin center-flex() {
  display: flex;
  align-items: center;
  justify-content: center;
}

// extra small
@mixin xs() {
  @media only screen and (max-width: #{$phone-width}) {
    @content;
  }
}

// small
@mixin sm() {
  @media only screen and (max-width: #{$tablet-width}) {
    @content;
  }
}

// medium
@mixin md() {
  @media only screen and (max-width: #{$sm-desktop-width}) {
    @content;
  }
}

// large
@mixin lg() {
  @media only screen and (max-width: #{$desktop-width}) {
    @content;
  }
}

// large
@mixin lg-1400() {
  @media only screen and (max-width: 1439px) {
    @content;
  }
}

$sides: (
  "top": "t",
  "right": "r",
  "bottom": "b",
  "left": "l",
);

// @each $prop, $letter in $sides {
//   @for $px from 0 through 20 {
//     .m#{$letter}-#{$px * 4} {
//       margin-#{$prop}: #{$px * 4}px !important;
//     }

//     .p#{$letter}-#{$px * 2} {
//       padding-#{$prop}: #{$px * 4}px !important;
//     }
//   }
// }

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

$browser-context: 16;

@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return calc($pixels / $context) * 1em;
}

@mixin font($font-size, $line-height: false, $font-weight: false) {
  font-size: em($font-size);
  /* #{$font-size} */

  @if $line-height !=false {
    line-height: $line-height;
  }

  @else {
    line-height: 1.5;
  }

  @if $font-weight !=false {
    font-weight: $font-weight;
  }
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin hidden() {
  display: none !important;
  visibility: hidden !important;
}

@mixin font-h1 {
  @include font(40px, 44px, 700);
  color: $neutral-100;

  @include sm() {
    @include font(32px, 35px, 700);
  }
}

@mixin font-h2 {
  @include font(36px, 44.5px, 700);
  color: $neutral-100;

  @include sm() {
    @include font(28px, 35px);
  }
}

@mixin font-h3 {
  @include font(32px, 40px, 700);
  color: $neutral-100;

  @include sm() {
    @include font(24px, 30px);
  }
}

@mixin font-h4 {
  @include font(28px, 37px, 700);
  color: $neutral-100;

  @include sm() {
    @include font(20px, 26.5px, 700);
  }
}

@mixin font-h5 {
  @include font(24px, 30px, 700);
  color: $neutral-100;

  @include sm() {
    @include font(18px, 22.5px);
  }
}

@mixin font-h6 {
  @include font(20px, 26.5px, 700);
  color: $neutral-100;

  @include sm {
    @include font(16px, 21px, 700);
  }
}

@mixin font-p1 {
  font-family: "Open Sans";
  @include font(20, 1.5, 700);
  color: $neutral-90;

  @include sm() {
    @include font(18, 1.65, 700);
  }
}

@mixin font-p2 {
  font-family: "Open Sans";
  @include font(20, 30px, 400);
  color: $neutral-90;

  @include sm() {
    @include font(16px, 24px, 400);
  }
}

@mixin font-p3 {
  @include font(16, 1.3, 400);
  color: $neutral-90;
}

@mixin font-p4 {
  @include font(14, 1.4, 600);
  color: $neutral-90;
}

@mixin font-p5 {
  @include font(14, 16px, 400);
  color: $neutral-90;

  @include sm() {
    @include font(12, 1.3, 400);
  }
}

@mixin font-p6 {
  @include font(12, 1.4, 400);
  color: $neutral-90;
}

@mixin string-cutter($line) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-word;
  line-clamp: $line;
  -webkit-line-clamp: $line;
}


@mixin line-clamp($count) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $count;
  line-clamp: $count;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin hide-scroll() {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}