.hero-section {
  padding-top: 0;
  background: linear-gradient(166.07deg, #182840 0%, #1F3049 25%, #293B58 49.94%, #2E4567 75%, #435F89 100%);
  color: $white;

  h2,
  h3,
  a {
    color: $white;
    transition: color 0.15s linear;

    svg {
      vertical-align: middle;
    }

    &:hover {
      color: $main;
    }
  }

  &__title {
    @include font(40px, 44px, 700);
    margin-bottom: -16px;

    @include md {
      @include font(32px, 36px, 700);
      margin-bottom: 0;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 8fr 4fr;
    gap: 32px;
    padding: 40px 0;

    @include md {
      gap: 15px;
      padding: 24px 0;
    }

    @include sm {
      grid-template-columns: 1fr;
      gap: 24px;
    }
  }

  &__sidebar {
    padding: 15px 0;

    @include md {
      padding: 0;
    }

    &-item {
      display: block;
      color: $white;
      @include font(20px, 26.4px, 700);
      padding: 24px 0;
      border-bottom: solid 1px rgba(210, 210, 210, 0.4);

      @include md {
        @include font(16px, 21px, 700);
        padding: 16px 0;
      }

      svg {
        @include size(20px, 20px);
        margin-left: 2px;

        @include md {
          @include size(16px, 16px);
        }
      }

      &:first-child {
        padding-top: 0;

        @include sm {
          padding-top: 16px;
          border-top: solid 1px rgba(210, 210, 210, 0.4);
        }
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }

  &__frame-wrapper {
    padding-bottom: 55.25%;
    position: relative;
  }

  &__main-item {

    img,
    iframe {
      width: 100%;
      height: auto;
      border-radius: 12px;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%, 100%);
      aspect-ratio: 16 / 9;
      object-fit: cover;
    }

    &:hover {
      h2 {
        color: $main;
      }
    }

    svg {
      @include size(32px, 32px);

      @include md {
        @include size(24px, 24px);
      }
    }

    h2 {
      @include font(32px, 40px, 700);
      color: $white;
      margin-top: 16px;

      @include md {
        margin-top: 12px;
        @include font(24px, 30px);
      }
    }
  }
}