.video-project {
  display: flex;
  flex-direction: column;
  color: $white;

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    // min-height: 171px;
  }

  &__text {
    height: fit-content;
  }

  &_large {
    img {
      margin-bottom: 8px;

      @include md {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    @include font(20px, 26.5px, 700);
    margin-top: 8px;

    @include md {
      @include font(18px, 22px);
    }

    a {
      color: $blue-main;
    }
  }

  &__description {
    @include font(16px, 24px, 400);
    color: $white;
    margin-top: 4px;
    display: block;
    position: relative;
    @include line-clamp(3);

    >span {
      position: absolute;
      color: $main;
      right: 0;
      bottom: 0;
      @include font(14px, 21px, 700);
      background-color: $blue-main;
      padding: 0 10px 0 4px;

      &::before {
        content: '...';
        position: absolute;
        left: 1px;
        color: $white;
        bottom: 1px;
        transform: translateX(-100%);
        background-color: rgba(24, 40, 64, 1);
        padding-left: 2px;
        padding-right: 1px;
        font-weight: 400;

      }
    }

    @include md {
      margin-top: 8px;
    }
  }
}