.war-elements {
  display: flex;
  background-color: $blue-main;
  overflow: hidden;
  color: $white;
  position: relative;
  top: 68px;

  @include md {
    top: 0;
  }

  >div {
    flex-direction: row;
    align-items: center;
  }

  &__title {
    position: relative;
    background: #182840;
    z-index: 22;
    min-width: fit-content;
    height: 32px;
    display: flex;
    align-items: center;
    @include font(14, 17px, 600);

    @include md() {
      @include font(12, 14.5px);
    }

    &::before {
      content: "";
      height: 100%;
      z-index: -1;
      position: absolute;
      background: linear-gradient(90deg, #182840 84.29%, rgba(24, 40, 64, 0) 100%);
      left: -40px;
      width: 195%;

      @include md() {
        left: 0;
        width: 120%;
      }

      top: 0;
    }
  }

  &__splide-war {
    cursor: pointer;
    width: 100%;
    margin-left: 0;

    @include sm() {
      margin-left: 0;
    }

    .splide {
      &__slide {
        padding: 6px 0;
        @include font(14, 17px, 400);

        @include md() {
          @include font(12, 14.5px);
        }

        padding-right: 40px;
        @include center-flex();

        >img {
          @include size(20px, 20px);
          margin-right: 16px;
        }

        >span {
          color: $main;
          margin-left: 4px;
          font-weight: 500;
        }
      }
    }
  }

  &-black {
    background-color: #121B30;

    .war-elements__title {
      &::before {
        background: linear-gradient(90deg, #121B30 84.29%, rgba(18, 27, 48, 0) 100%);
      }
    }
  }
}