.regions-slider {
  margin-bottom: 48px;

  @include md {
    margin-bottom: 40px;
  }

  &__date {
    @include font(12, 20px, 500);
    color: $main;
    display: block;
    margin-bottom: -12px;
  }

  .btn-news {
    margin-top: 30px;

    @include md {
      margin-top: 24px;
    }
  }

  .sidebar__items-list {
    padding-right: 0;
  }

  // .splide {
  //   &__list {}

  //   &__slide {}
  // }
}