@import "../utils/variables";
@import "../utils/mixins";

@import "../blocks/home/setup.scss";
@import "../blocks/videos/video-item.scss";
@import "../blocks/views/view-item.scss";
@import "../blocks/videos/video-project-head";
@import "../blocks/videos/video-project";
@import "../blocks/videos/heroes-tabs.scss";
@import "../blocks/videos/splide-video-slider.scss";
@import "../blocks/videos/broadcasts-sidebar.scss";

.sidebar-charter {
  max-width: 100%;
}


.slider-promo-thumbnails {
  margin-top: 32px;
  max-width: 976px;

  @include sm() {
    margin-top: 24px;
  }

  .splide__arrow--prev {
    display: none;
  }

  .splide__arrow--next {
    @include size(32px, 32px);
    border: solid 1.5px $neutral-100;
    opacity: 1;
    border-radius: 100%;
    z-index: 10;
    background-color: transparent;
    right: -16px;
    transform: translate(100%, -50%);
    top: 50%;

    @include lg-1400 {
      right: 0;
      transform: translate(50%, -50%);
    }

    @include sm {
      display: none;
    }

    svg {
      @include size(20px, 20px);
    }
  }

  &__track {
    @include sm {
      width: calc(100vw - 24px);
    }
  }

  &__slide {
    border-radius: 8px;
    padding: 16px;
    min-height: 115px;
    overflow: hidden;
    border: solid 1.5px rgba(210, 210, 210, 0.5);
    max-width: 304px;
    width: 100%;
  }

  &__title {
    @include font(16px, 21px, 400);
    margin-top: 8px;
    // @include line-clamp(3);
    white-space: normal;
    word-break: break-word;

    a {
      color: $neutral-100;
    }
  }

  &__date {
    @include font(12, 16px, 400);
    color: $neutral-80;
  }

  // &__track {
  //   .slider-promo-thumbnails__list {
  //     .slider-promo-thumbnails__slide.is-active {
  //       border: none;
  //     }

  //     .splide__slide {
  //       border: none;
  //     }
  //   }
  // }
}

.all-videos-btn {
  @include font(20, 28px, 600);
  color: $main;
  border: solid 1px $main;
  padding: 9px 18px;
  border-radius: 12px;
  transition: 0.2s ease;

  &:hover {
    background-color: $main;
    color: $text-color;
  }

  &_mob {
    display: none;

    @include md {
      @include center-flex();
      border-radius: 8px;
      height: 42px;
      padding: 0;
      padding-bottom: 2px;
      @include font(16, 22.4px);
    }
  }
}

.videos-projects {
  padding-top: 32px;
  border-top: solid 1px rgba(210, 210, 210, 0.4);

  &__title {
    color: $main;
    @include font(28px, 37px, 700);

    @include sm {
      @include font(20px, 26.5px);
    }
  }

  @include sm {
    padding-top: 28px;
  }

  &__inner {
    padding-top: 24px;
    display: flex;
    flex-direction: column;

    @include md {
      padding-top: 16px;
    }
  }

  &__first {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    margin-bottom: 32px;
    align-items: center;

    @include lg {
      gap: 20px;
    }

    @include sm {
      grid-template-columns: 1fr;
      gap: 8px;
      margin-bottom: 36px;
    }

    .video-project__title {
      @include font(32px, 40px, 700);
      margin-top: 0;

      @include lg {
        @include font(20px, 26.5px, 700);
      }
    }

    .video-project__description {
      @include font(24px, 32px, 400);
      margin-top: 8px;
      @include line-clamp(8);

      @include lg {
        @include font(16px, 24px, 400);
      }

      @include md {
        @include line-clamp(6);
      }

      @include sm {
        @include line-clamp(5);
      }

      >span {
        display: none;

        @include sm {
          display: inline;
        }
      }
    }

    img {
      border-radius: 12px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;

    @include md {
      gap: 24px;
    }

    @include sm {
      display: flex;
      gap: 0;
      flex-wrap: nowrap;
      overflow: auto;
      width: calc(100% + 24px);
      @include hide-scroll();

      .video-project {
        margin-right: 24px;

        img {
          min-height: 136px;
          min-width: 304px;
        }
      }
    }
  }

  &__btn {
    display: block;
    border: solid 1.5px #E52D27;
    border-radius: 32px;
    @include size(416px, 48px);
    color: $white;
    @include center-flex();
    @include font(16px, 24px, 600);
    margin: 40px auto 0;

    @include sm {
      margin-top: 32px;
    }

    svg {
      margin-left: 8px;
    }

    @include xs {
      width: 100%;
    }

  }
}

.broadcasts-gap {
  margin-bottom: 32px;

  @include md {
    margin-bottom: 0;
  }
}

.views {
  margin-bottom: 32px;

  @include sm {
    margin-bottom: 24px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      color: $main;
      @include font(14px, 20px, 600);
    }
  }

  &__items {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;

    @include lg {
      gap: 16px;
    }

    @include md {
      grid-template-columns: 1fr 1fr;
      gap: 32px;
    }

    @include sm {
      gap: 0;
      display: flex;
      flex-wrap: nowrap;
      overflow: scroll;
      @include hide-scroll();
      width: calc(100vw - 24px);

      >div,
      >a {
        margin-right: 16px;
      }
    }
  }

}

.seo-block-gap {
  margin-bottom: 48px;

  .seo-text__head {
    margin-bottom: 16px;

    @include sm {
      margin-bottom: 0
    }
  }
}

@import "../blocks/videos/tv-hosts.scss";
@import "../blocks/shared/seo-text.scss";