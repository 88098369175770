.video-section {
  width: 100%;
  padding-top: 56px;
  padding-bottom: 112px;
  background-color: $blue-main;
  // content-visibility: auto;

  @include sm() {
    padding-bottom: 56px;
    padding-top: 40px;
    background-color: $blue-main;
  }

  position: relative;
  // max-height: 500px;

  &-gap {
    margin-bottom: 40px;
    @include sm() {
      margin-bottom: 0;
    }
  }

  &__btn {
    margin-top: 0;
  }

  .overlay {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-size: cover;
    top: 0;
    left: 0;
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white;

    &-title {
      color: $white;

      a {
        @include font(36, 47px, 700);
      }
    }
  }

  &__content {
    display: flex;
    @include sm() {
      flex-direction: column;
    }
    margin-top: 25px;
    @include sm() {
      margin-top: 20px;
    }

    a {
      text-decoration: none;
    }

    h3,
    h2 {
      color: $white;
    }
  }

  &__main-video {
    margin-right: 32px;
    @include sm() {
      margin-right: 0;
    }

    &-img-wrapper {
      height: 360px;
      @include sm() {
        height: 171px;
      }

      &-mob {
        display: none;
        @include sm() {
          display: block;
        }
      }

      &-big-svg {
        @include sm() {
          display: none;
        }
      }

      display: block;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__date {
    text-transform: uppercase;
    @include font(12, 17px, 400);
    margin-top: 16px;
    color: $white;
    display: block;
    margin-bottom: 6px;
  }

  &__date-first {
    margin-top: 32px;
    margin-bottom: 24px;
    @include sm() {
      margin-top: 16px;
      margin-bottom: 6px;
    }
  }

  &__grid-videos {
    display: grid;
    width: 90%;
    grid-template-columns: 1fr 1fr;
    @include md() {
      grid-template-columns: 1fr;
    }
    gap: 28.5px;
    @include sm() {
      grid-template-columns: 1fr 1fr;
      margin-top: 32px;
      gap: 32px;
      width: 100%;
    }
    @include xs() {
      grid-template-columns: 1fr;
    }
  }

  &__main-video-title {
    @include font(32, 45px, 600);
    @include sm() {
      @include font(22, 28.6px, 600);
    }
  }

  &__grid-item {
    height: fit-content;

    &-title {
      @include font(16, 24px, 600);
      @include sm() {
        @include font(22, 28.6px, 600);
      }
    }

    img {
      width: 100%;
      display: block;
      height: 100%;
      object-fit: cover;
    }
  }

  &__link {
    position: relative;
    display: block;

    svg {
      position: absolute;
      left: 16px;
      bottom: 16px;
      @include sm() {
        left: 8px;
        bottom: 8px;
      }
    }

    &-small {
      // TODO: FIX to max-height WHEN NORMAL PHOTOS APPEAR
      height: 171px;

      svg {
        left: 8px;
        bottom: 8px;
        z-index: 10;
      }
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(
                      180deg,
                      rgba(0, 0, 0, 0) 66.39%,
                      rgba(0, 0, 0, 0.5) 100%
      );
    }
  }

  &__btn-mob {
    display: none;
    @include xs() {
      display: block;
      color: $white;
      min-width: 100%;
      margin-top: 25px;
    }
  }
}
