.sidebar-lifestyle {
  &__link-btn {
    @include sm() {
      display: none;
    }
  }

  &__load-more-btn {
    display: none;
    @include sm() {
      display: flex;
    }
  }

  .sidebar__inner {
    padding: 0;
    padding-right: 0 !important;
  }

  &-tab-content {
    @include sm() {
      overflow-y: auto !important;
      max-height: 100% !important;
    }
  }
}
