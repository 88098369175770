@import "war-elements";
@import "hero-section";
@import "sidebar";
@import "sidebar-last";
@import "sidebar-item";
@import "content-grid-three";
@import "content";
@import "regions-slider-thumbnails";
@import "video-section";
@import "section-charter";
@import "regions-slider";
@import "columns";
@import "promo-slide";
@import "sidebar-lifestyle";

.grid-block {
  display: grid;
  grid-template-columns: 2.85fr 9.15fr;
  gap: 32px;

  &_sidebar {

    // hide broadcast sidebar
    //.sidebar-popular {
    //  margin-top: 32px;
    //}

    @include md {
      .broadcasts-sidebar {
        max-width: 100vw;
      }

      .sidebar-popular {
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  @include md() {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    max-width: 100%;
    gap: 40px;
  }

  &_space {
    margin-top: 64px;

    @include md() {
      margin-top: 0;
    }

    margin-bottom: 32px;

    @include md() {
      margin-bottom: 64px;
    }
  }

  &_space-tags {
    margin-top: 0;
    margin-bottom: 24px;
  }

  &_space-reverse {
    @include md() {
      margin-top: 0;
    }

    margin-top: 32px;
  }

  &__wrapper {
    >.section-charter {
      margin-top: 40px;

      @include sm {
        margin-top: 0;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &_home-gap {
    margin-bottom: 100px;

    @include sm() {
      margin-bottom: 0;
    }
  }


  &_reverse {
    grid-template-columns: 9.15fr 2.85fr;

    @include md() {
      grid-template-columns: 1fr;
    }
  }
}

.grid-block-2 {
  display: grid;
  grid-template-columns: 2.85fr 9.15fr;
  gap: 32px;
  margin-top: 48px;

  @include md {
    margin-top: 24px;
    grid-template-columns: 1fr;
    gap: 16px;

    >div {
      &:first-child {
        order: 1;
      }

      &:last-child {
        order: 0;
      }
    }

    .sidebar {
      width: calc(100vw - 48px);
    }
  }
}