.video-item {
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  &__title {
    @include font(16, 21px, 700);
    margin-top: 8px;
    margin-bottom: 4px;

    a {
      color: $blue-main;
    }
  }

  &__date {
    @include font(12, 16px, 400);
    color: $blue-main-border;
  }

  &__link {
    position: relative;
    @include size(100%, auto);
  }

  &__play {
    position: absolute;
    bottom: 3px;
    left: 0;
    display: block;
    @include size(40px, 40px);
    @include center-flex();
    background-color: rgba(151, 158, 169, 0.5);
    border-radius: 0px 5px 0px 8px;
  }
}