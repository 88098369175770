.content {
  &_small-gap {
    margin-bottom: 24px;
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    a {
      text-decoration: none;
      color: $main;
      @include font(14, 17px, 600);
      margin-left: 24px;
    }

    &_mob-column {
      @include xs() {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &__title {
    color: $main;
    @include font(28px, 37px, 700);

    @include sm() {
      @include font(20px, 26.5px);
    }
  }

  &__tag-title {
    @include font(20, 26px, 400);
    color: #707070;
    margin-left: auto;
    padding-left: 30px;

    @include xs() {
      margin-left: 0;
      padding-left: 0;
      margin-top: 8px;
    }
  }

  &_regions {
    margin-top: 40px;
  }
}

.content-wrapper {
  overflow: hidden;

  &_events-of-the-day {
    @include md {
      width: calc(100vw - 24px);
    }
  }

  .btn-mob {
    display: none;

    @include md() {
      display: flex;
      margin-top: 32px;

      img {
        position: relative;
        top: 2px;
      }
    }

    @include sm() {
      min-width: 100%;
    }
  }

  .content__title-wrapper_reg {
    @include md() {
      padding-right: 24px;
    }
  }
}

.events-of-the-day {
  .adv {
    margin-top: 8px;
    grid-column: span 3;
  }

  @include md {
    padding-right: 24px;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    @include hide-scroll();
    gap: 0;

    >div {
      min-width: 304px;
      margin-right: 24px;
    }
  }
}