.seo-text {
  background-color: #243959;
  padding-top: 41px;
  padding-bottom: 32px;
  color: $white;

  @include sm {
    padding: 24px 0;
  }

  &_full {
    max-height: 100%;

    .seo-text__content {
      max-height: 100%;

      &::before {
        display: none;
      }
    }

    button.seo-text__show-more {
      position: relative;
      margin-top: 24px;

      @include sm {
        margin-top: 16px;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color: $white;
    color: $main;
    display: inline;
  }

  &__head {
    max-width: 660px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    img {
      @include sm {
        @include size(108px, 36px);
      }
    }

    @include sm {
      flex-direction: column;
    }

    h1 {
      @include font(28px, 37px, 700);
      margin-left: 24px;
      color: $white;

      @include sm {
        margin-left: 0;
        margin-top: 16px;
        @include font(20px, 26.5px);
        text-align: center;
      }

      b,
      strong {
        color: $main;
      }
    }
  }

  &__content {
    max-width: 640px;
    max-height: 280px;
    margin: 16px auto 0;
    width: 100%;
    position: relative;
    overflow: hidden;

    @include sm {
      max-height: 186px;
    }

    b {
      color: $main;
    }

    h2 {
      width: 100%;
      display: block;
      text-align: center;
      margin-bottom: 24px;
      @include font(36px, 44.5px, 700);

      @include sm {
        margin-bottom: 16px;
        @include font(20px, 26.5px);
      }
    }

    h3,
    h4,
    h5,
    h6 {
      @include font(20px, 30px, 700);
      margin-top: 24px;
      display: block;

      @include sm {
        @include font(16px, 24px);
      }

      &::before {
        content: '';
        width: 32px;
        height: 1px;
        display: block;
        margin-bottom: 8px;
        background-color: rgba(210, 210, 210, 0.4);
      }
    }

    strong,
    b {
      font-weight: 700;
    }

    p {
      @include font(20px, 30px, 400);

      @include sm {
        @include font(16px, 24px);
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 84px;
      background: linear-gradient(0deg, #253A5A 37.5%, rgba(37, 58, 90, 0) 154.41%);
      bottom: 0;
      left: 0;
      z-index: 1;

      @include sm {
        height: 70px;
        background: linear-gradient(360deg, #253A5A 35%, rgba(37, 58, 90, 0) 100%);
      }
    }

  }

  .seo-text__show-more {
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 3;
    transform: translateX(-50%);
    border-width: 1px;
    color: $white;
    width: fit-content;
    padding: 10px 16px;

    @include sm {
      padding: 6px 12px;
      bottom: 0;
      @include font(12px, 18px, 400);
    }

    &:hover {
      background-color: $main;
    }
  }
}