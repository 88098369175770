.tv-hosts {
  background-color: $blue-main;
  padding-bottom: 48px;
  overflow: hidden;
  margin-bottom: 40px;

  @include md {
    padding-bottom: 40px;
    margin-bottom: 32px;
  }

  // container
  >div {
    position: relative;
  }

  .video-item {
    a {
      color: $white;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    margin-top: 48px;

    @include sm {
      margin-top: 16px;
    }
  }

  &__title {
    @include font(48, 58.5px);
    color: $blue-main-border;

    padding-left: 40px;

    @include md {
      padding-left: 0;
      @include font(24, 29.25px);
    }
  }

  &__tabs {
    margin-top: 56px;
    margin-bottom: 32px;

    // @include md {
    //   margin: 0 auto;
    //   margin-top: 16px;
    // }
    @include sm {
      margin: 24px 0;
      margin-bottom: 16px;
    }
  }

  &__imagesWrapper {
    border-bottom: solid 1px #979EA9;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      @include size(1440px, 660px);
      background-size: cover;
      background-image: url('/src/images/video/circle/desktop.png');

      @include md {
        @include size(1024px, 770px);
        background-image: url('/src/images/video/circle/tablet.png');
      }

      @include sm {
        @include size(375px, 351px);
        background-image: url('/src/images/video/circle/mobile.png');
      }
    }
  }

  &__images {
    position: relative;
    display: flex;
    max-width: 1400px;
    margin: 0 auto;
    align-items: flex-end;

    .splide__track {
      width: 100%;
      transition: padding-left 0.5s ease-in-out;

      .splide__slide.is-visible {
        pointer-events: all;
      }

      .splide__slide:not(.is-visible) {
        pointer-events: none;
      }
    }


    @media (max-width: 1440px) {
      overflow: hidden;
      max-width: 100%;

      .splide__track {
        position: relative;
      }


    }

    @include sm {
      max-width: 640px;
    }

    // @include xs {
    //   height: 285px;
    // }
  }


  &__image {
    width: fit-content;
    display: block;
    transition: 0.2s ease-in-out;
    transition-property: filter, transform;

    &_active {
      filter: drop-shadow(-2px 2px 8px rgba(255, 117, 52, 1));
      transform: scale(1.02);
    }

    @include sm {
      img {
        @include size(140px, auto)
      }
    }
  }

  &__slide {
    position: relative;
    z-index: 1;
    transition:
      top 1s cubic-bezier(.44, .65, .07, 1.01),
      width 1s cubic-bezier(.44, .65, .07, 1.01),
      opacity 1s cubic-bezier(.44, .65, .07, 1.01),
      // margin 1s cubic-bezier(.44, .65, .07, 1.01),
      height 1s cubic-bezier(.44, .65, .07, 1.01);

    opacity: 0;
    margin-left: 0;
    top: 10px;


    img {
      transition: transform 1s cubic-bezier(.44, .65, .07, 1.01),
        width 1s cubic-bezier(.44, .65, .07, 1.01),
        top 1s cubic-bezier(.44, .65, .07, 1.01),
        height 1s cubic-bezier(.44, .65, .07, 1.01),
        opacity 1s cubic-bezier(.44, .65, .07, 1.01) !important;
      object-fit: contain;
      position: relative;

      @include lg-1400 {
        transition: transform 1s cubic-bezier(.44, .65, .07, 1.01),
          // width 1s cubic-bezier(.44, .65, .07, 1.01),
          height 1s cubic-bezier(.44, .65, .07, 1.01),
          opacity 1s cubic-bezier(.44, .65, .07, 1.01) !important;
        object-fit: contain;
        position: relative;
      }

      width: 360px;
    }

    &_overlay {
      z-index: 0;

      &::before {
        content: '';
        position: absolute;
        filter: blur(16px);
        top: 0;
        left: 0;
        @include size(100%, 100%);
        background: var(--tintGrad_50_reversed, linear-gradient(90deg, rgba(24, 40, 64, 0.00) 0%, rgba(24, 40, 64, 0.35) 32.81%, rgba(24, 40, 64, 0.65) 100%));
        z-index: 1;
      }

      top: 18px;

      @include sm {
        top: 0;
      }

      img {
        width: 350px;
        height: 480px;
        filter: blur(0.5px);
      }
    }

    @include sm {
      top: 38px;
      will-change: top, opacity, margin;

      img {
        @include size(150px, 249px);
      }
    }

    &.is-prev,
    &.is-next {
      background: transparent;
      z-index: 50 !important;

      img {
        position: relative;
        z-index: 50;
      }
    }

    &.is-prev,
    &.is-next {
      transition: none;
      top: 1px !important;

      @include sm {
        top: 20px !important;
        @include size(160px, 266px);
      }
    }

    &.is-active,
    &.is-prev,
    &.is-next {
      transition: 0.8s ease-in-out;
      transition-property: opacity, top, margin;
      opacity: 1 !important;

      img {
        filter: none;
        @include size(370px, 556px);

        @include sm {
          @include size(175px, 266px);
        }
      }
    }

    &.is-active {
      transition: 0.5s ease-in-out;
      transition-property: opacity, top, width;
      z-index: 51 !important;
      margin-left: -25px;
      top: 0;

      img {
        @include size(370px, 556px);
      }


      @include sm {
        img {
          @include size(180px, 266px);
        }
      }
    }
  }

  &__slider {
    margin-top: 48px;

    @include md {
      margin-top: 32px;

      .splide {
        margin-left: -24px;
        padding-left: 24px;
      }
    }

    .splide__track {
      padding-bottom: 35px;

      @include md {
        padding-bottom: 32px;
      }
    }

    .splide__arrows {
      margin-right: auto;
      margin-left: 0;
      user-select: none;
    }
  }
}

// .minus-anim {
//   animation: move 1s ease-in forwards;
// }

// @keyframes move {
//   0% {
//     z-index: 100;
//     opacity: 1;
//   }

//   50% {
//     opacity: 0.5;
//   }

//   100% {
//     z-index: 100;
//     opacity: 1;
//   }
// }