.sidebar-item {

  &_popular {
    display: flex;

    &:last-child {
      .sidebar-item__counter {
        margin-left: -14px;
        letter-spacing: -0.1em;
      }
    }
  }

  &__time {
    @include font(14, 16px, 400);
    color: #ADADAD;
    margin-right: 8px;
    position: relative;
    top: 3px;
    float: left;
  }

  &__counter {
    @include font(45px, 45px, 700);
    color: $main;
    margin-right: 16px;
    white-space: nowrap;
    max-width: 48px;
  }

  word-break: break-word;

  padding: 16px 0;
  border-bottom: solid 1px $neutral-30;

  &_social {
    @include xs() {
      padding-top: 16px;
    }

    a {
      display: flex;
      text-decoration: none;
      color: $blue-main;

      @include md() {
        align-items: center;
      }

      @include xs() {
        align-items: flex-start;
      }

      svg {
        min-width: 30px;
        min-height: 30px;

        path {
          transition: fill 0.2s ease-in-out;
        }
      }

      &:hover {
        svg {
          path {
            fill: $main;
          }
        }
      }
    }

    &-title {
      @include font(16, 21px, 600);
      margin-left: 11px;

      @include xs() {
        margin-left: 17px;
      }

      @include md() {
        position: relative;
        top: 3px;
      }
    }
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &_small-gap {
    padding: 16px 0;

    &:last-child {
      padding-bottom: 16px;
    }
  }

  &__img {
    display: flex;
    justify-content: space-between;
  }

  &__date {
    text-transform: uppercase;
    color: $neutral-80;
    @include font(12, 16.8px, 400);
    margin-right: 16px;
    position: relative;
    top: 3.5px;
  }

  .promo-container {
    display: flex;
    align-items: center;
    /* Для центрування зображення по вертикалі */
    flex-direction: row;
  }

  .promo-container img {
    @include size(80px, 80px);
    border-radius: 4px;
    object-fit: cover;
    margin-left: 8px;
    margin-right: 16px;

    @include sm() {
      display: inline-block;
    }
  }

  h4 {
    .sidebar-item__title_alarm {
      font-weight: 600;
    }

    a {
      @include font(16, 22px, 500);
      text-decoration: none;
      color: $blue-main;
    }
  }

  &__tag {
    margin-bottom: 8px;
    display: inline-flex;
    align-items: center;
    height: 20px;
    margin-right: 14px;

    &_small-gap {
      margin-bottom: 8px;
    }
  }

  &__updated {
    border-radius: 4px;
    background-color: #F7F7F7;
    height: 26px;
    display: none;

    @include md() {
      display: inline-flex;
    }

    align-items: center;
    width: fit-content;
    padding-left: 6px;
    padding-right: 8px;
    position: relative;
    top: 0px;
    margin-bottom: 16px;

    span {
      @include font(14, 19.6px, 600);
      color: $neutral-60;
      position: relative;
      top: 1px;
    }

    img {
      display: block;
      width: 16.5px;
      height: 15px;
      margin-right: 10px;
      margin-left: 0;
    }
  }

  &__tag-title {
    @include font(14, 19.5px, 600);
    margin-left: 12px;
    position: relative;
    color: $blue-main-border;
  }

  &__tag-title_alarm {
    color: $main;
  }

  &_video {
    padding-top: 24px;

    h4 {
      margin-top: 8px;
    }

    h4 a {
      font-weight: 700;
    }

    .video-item__link {
      max-width: 100%;
      display: block;
      position: relative;

      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }

    .video-item__play {
      @include size(40px, 40px);
      border-radius: 0px 5px 0px 5px;
      bottom: 2.5px;
      position: absolute;

      svg {
        @include size(18px, 21px);
      }
    }
  }

  &_article {
    padding: 12px 0;
    min-height: 84px;

    &:first-child {
      min-height: 72px;
    }

    h4 {
      @include line-clamp(3);

      @include sm {
        -webkit-line-clamp: none;
        line-clamp: none;
        display: inline;
      }
    }

    img {
      border-radius: inherit;
      object-fit: cover;
      @include size(100%, 100%);
    }

    &-link {
      @include size(60px, 60px);
      float: left;
      border-radius: 4px;
      margin-right: 12px;
    }
  }
}

.sidebar-item-view {
  >svg {
    min-width: 80px;

    image {
      filter: grayscale(1);
    }
  }

  display: flex;

  &__position {
    color: $main;
    @include font(16px, 19.2px, 500);
  }

  &__title {
    @include font(16px, 22.4px, 700);
    color: #182840;
    margin-top: 4px;
    @include line-clamp(4);

    @include sm {
      @include line-clamp(10);
    }
  }

  &__content {
    margin-left: 10px;
  }
}