.section-charter {
  height: fit-content;
  border-bottom: solid 1px rgba(210, 210, 210, 0.4);
  padding-bottom: 48px;

  .grid-item {
    @include sm {
      border-bottom: solid 1px #EBEBEB;
      padding-bottom: 16px;
    }
  }

  @include sm() {
    border-radius: 0;
    padding-bottom: 40px;
    border-bottom: none;
  }

  &_hashtag {
    border-bottom: none;
    margin-top: 0;
  }

  &_promo {

    .section-charter__title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .section-charter__title {
        margin-bottom: 0;
      }

      @include sm {
        margin-bottom: 16px;
      }
    }

    .btn {
      margin-top: 24px;
    }

  }

  &__title {
    @include font(28px, 37px, 700);
    color: $main;
    margin-bottom: 16px;

    @include sm {
      @include font(20px, 26.5px, 700);
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 6fr 2.85fr;

    @include sm() {
      display: flex;
      margin-bottom: 24px;
    }

    gap: 32px;

    &-grid {
      display: flex;
      flex-direction: column;

      .btn {
        margin-top: 24px;
      }

      @include sm() {
        display: none;
      }

      &-double {
        margin-top: 24px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;
      }
    }
  }


  &__sidebar-items {

    &-hidden {
      display: none;
    }
  }

  &__big-grid-item {
    max-height: 360px;
  }

  .btn-news {
    margin-top: 24px;
  }
}