.sidebar {
  background-color: $white;
  border-radius: 12px;
  height: 100%;
  max-width: 304px;
  width: 100%;

  @include xs {
    max-width: 100%;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    padding-right: 22px;
    margin-right: 22px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $body;
    padding-right: 22px;
    margin-right: 22px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $neutral-40;
    border-radius: 3px;
    padding-right: 22px;
    margin-right: 22px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $neutral-50;
  }

  @include sm() {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__items-list {
    padding-right: 6px;

    @include md() {
      overflow-y: auto !important;
      max-height: 100% !important;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    height: fit-content;

    .btn {
      margin-top: 24px;
      position: relative;
    }
  }

  &__top-line {
    padding-bottom: 16px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;

    .section-charter__title {
      @include sm {
        margin-bottom: 0;
      }
    }

    h3 {
      color: $main;
      @include font(28px, 37px, 700);

      @include sm {
        @include font(20px, 26.5px);
      }
    }

    a {
      position: relative;
      text-decoration: none;
      @include font(14, 20px, 600);
      color: $main;
      border-bottom: solid 1px $main;
      transition: border-color 0.2s linear;
      margin-bottom: 2px;

      &:hover {
        border-color: transparent;
      }
    }
  }

  &__top-line_charter {

    h3 {
      @include font(28px, 37px, 700);
      color: $main;

      @include sm() {
        display: none;
      }
    }
  }

  &__top-line_regions {
    h3 {
      @include font(20px, 26.5px, 700);
      color: $text-color;
    }

    @include xs {
      display: none;
    }
  }

  &__date-wrapper {
    &:last-child {
      .sidebar-item {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  &__date {
    color: $main;
    @include font(12px, 20px, 500);
    display: block;
    padding-top: 12px;
    border-top: solid 2px $white;
    position: relative;
    width: 100%;
    margin-bottom: -12px;

    &_article {
      margin-bottom: -4px;
    }
  }

  &__additional-title {
    display: none;

    @include sm() {
      display: block;
    }
  }

  &__scrollable {
    position: relative;

    &::before {
      @include md() {
        display: none;
      }

      content: "";
      width: 97%;
      height: 76px;
      position: absolute;
      bottom: 60px;
      left: 0px;
      background: linear-gradient(360deg,
        #ffffff 34.24%,
        rgba(255, 255, 255, 0) 105.15%);
    }
  }
}