.regions-slider-thumbnails {
  display: none;

  @include md() {
    display: block;
    margin-bottom: 16px;
  }

  &.splide {
    .splide__slide {
      border: none;
      background-color: transparent;
      padding: 2px 8px;
      border-radius: 4px;
      @include font(12px, 16px, 500);
      color: #D2D2D2;
    }

    .splide__slide.is-active {
      border: none;
      color: $main;
      background-color: #FEF4EF;
    }
  }
}