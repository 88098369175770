.splide-video-slider {
  z-index: 10;
  position: relative;

  @include md {
    background: linear-gradient(180deg, rgba(24, 40, 64, 0) 0%, #182840 31.3%, #182840 100%);
  }

  .splide__track {
    padding-bottom: 40px;
    background: linear-gradient(180deg, rgba(24, 40, 64, 0) 0%, #182840 31.3%, #182840 100%);

    @include sm {
      padding-bottom: 24px;
    }
  }

  .splide__arrows {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    top: 69px;

    @include lg {
      top: 20%;
    }

    @include md {
      display: none;
    }

    .splide__arrow {
      border: solid 1.5px $white;
      border-radius: 32px;
      @include size(32px, 32px);
      background-color: transparent;
      opacity: 1;

      svg {
        @include size(20px, 20px);
      }
    }

    .splide__arrow--prev {
      transform: translateX(-100%);
      left: -16px;

      @include lg-1400 {
        left: 0;
        transform: translateX(-50%);
      }

      svg {
        transform: rotate(360deg);
      }
    }

    .splide__arrow--next {
      right: -16px;
      transform: translateX(100%);

      @include lg-1400 {
        right: 0;
        transform: translateX(50%);
      }
    }
  }

  .video-item {
    @include xs {
      max-width: 304px;
    }

    .video-item__play {
      bottom: 0;
    }
  }
}

.video-slider {
  .splide__slide {
    @include xs {
      min-width: 304px;
    }
  }

  &__btn {
    margin-right: 16px;
  }

  @include md {
    width: 105%;
  }

  @include sm {
    width: 108%;
  }

  @include xs {
    width: 108%;
  }

  &__title {
    @include font(32, 44.8px, 700);
    color: $main;

    @include md {
      @include font(20, 24px);
      color: $blue-main-border;
    }
  }
}